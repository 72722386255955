import React from 'react'

import ReactDOM from 'react-dom'

import './index.scss'

function importBuildTarget() {
	if (process.env.REACT_APP_BUILD_TARGET === 'customer') {
		return import('./customer/App')
	} else if (process.env.REACT_APP_BUILD_TARGET === 'chief') {
		return import('./chief/App')
	} else {
		return Promise.reject(
			new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET)
		)
	}
}

importBuildTarget().then(({ default: Environment }) => {
	ReactDOM.render(
		<React.StrictMode>
			<Environment />
		</React.StrictMode>,
		document.getElementById('root')
	)
})
